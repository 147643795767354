const quotes = [
"Go get 'em tiger!",
"You. Got. This.",
"I believe in you!",
"Next stop, employed!",
"This is what you've been waiting for.",
"Just keep trucking!",
"Yeaaaah, get it!",
"You are a winner!",
"Just wait 'till they hear from you!",
"Don't look back",
"It's game time"
];

export default quotes;